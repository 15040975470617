/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    code: "code",
    h3: "h3"
  }, _provideComponents(), props.components), {Example} = _components;
  if (!Example) _missingMdxReference("Example", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "installation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#installation",
    "aria-label": "installation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Installation"), "\n", React.createElement(_components.p, null, "Themes CSS files are located on ", React.createElement(_components.code, null, "dist/css/themes/"), " directory.\nPick the one of them and link to the file. See ", React.createElement(_components.a, {
    href: "/v3/guides/getting-started/#importing-css"
  }, "this document"), " for more details."), "\n", React.createElement(_components.h2, {
    id: "preview",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#preview",
    "aria-label": "preview permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Preview"), "\n", React.createElement(_components.h3, {
    id: "default",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#default",
    "aria-label": "default permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Default"), "\n", React.createElement(Example, {
    id: "default",
    sourceId: "theme-default",
    theme: "default",
    isTheme: true,
    hasCode: false
  }), "\n", React.createElement(_components.h3, {
    id: "skyblue",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#skyblue",
    "aria-label": "skyblue permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Skyblue"), "\n", React.createElement(Example, {
    id: "skyblue",
    sourceId: "theme-skyblue",
    theme: "skyblue",
    isTheme: true,
    hasCode: false
  }), "\n", React.createElement(_components.h3, {
    id: "sea-green",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sea-green",
    "aria-label": "sea green permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "Sea Green"), "\n", React.createElement(Example, {
    id: "sea-green",
    sourceId: "theme-sea-green",
    theme: "sea-green",
    isTheme: true,
    hasCode: false
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
